import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			// deviceType: 'pc',
			deviceType: 'mobile',
			showDebug: false,
			adsensConfig: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3125188477567991',
				ads: 'google.com, pub-3125188477567991, DIRECT, f08c47fec0942fa0',
				home_times: [
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-3125188477567991', 'data-ad-slot': '3836305902', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-3125188477567991', 'data-ad-slot': '3836305902', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-3125188477567991', 'data-ad-slot': '1929162013', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				],
				home_full: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-3125188477567991', 'data-ad-slot': '3217358800', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_blog: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-3125188477567991', 'data-ad-slot': '9616080343', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				blog_1_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-3125188477567991', 'data-ad-slot': '5258594013', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				blog_1_2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-3125188477567991', 'data-ad-slot': '8302998670', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			},
			adPosConfig: {
				home_pc: [3, 9, 18],
				home_m: [1, 3, 6],
			},
			firebaseConfig: {
				apiKey: 'AIzaSyBcS3cwlUXpK99s0FiNLcdhiTqTbqa8pRo',
				authDomain: 'webs-58a8d.firebaseapp.com',
				projectId: 'webs-58a8d',
				storageBucket: 'webs-58a8d.appspot.com',
				messagingSenderId: '730684174767',
				appId: '1:730684174767:web:a7b48afc79c7a6f80c3f5a',
				measurementId: 'G-J6ZDGQ4QS9',
			},
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			adPosConfig: (state) => state.adPosConfig,
		},
	})
}
